import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FirstArrowIcon from '../icons/first.svg';
import PreviousArrowIcon from '../icons/previous.svg';
import LastArrowIcon from '../icons/last.svg';
import NextArrowIcon from '../icons/next.svg';
import MagnifierArrowIcon from '../icons/magnifier.svg';

export interface SliderProps {
    slidesAmount: number;
    slug: string;
    imagesSrc?: string;
    active?: number;
    imgExt?: string;
}

const useStyles = makeStyles<Theme, {isZoomed: boolean}>({
    wrap: {
        position: (theme) => theme.isZoomed ? 'fixed' : 'relative',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        width: '100%',
        maxWidth: (theme) => theme.isZoomed ? '100%' : 'initial',
        background: (theme) => theme.isZoomed ? '#ccc' : 'transparent',
        zIndex: (theme) => theme.isZoomed ? 1 : 'initial',
    },
    img: {
        width: '100%',
        height: (theme) => theme.isZoomed ? 'calc(100% - 50px)' : '100%',
        display: 'block',
        objectFit: 'contain',
    },
    input: {
        height: 40,
        width: 60,
        margin: '0 10px',
        fontSize: 18,
        border: '1px solid #aaa',
        borderRadius: 4,
        textAlign: 'center',
    },
    actionPanel: {
        position: (theme) => theme.isZoomed ? 'fixed' : 'relative',
        bottom: (theme) => theme.isZoomed ? 0 : 'initial',
        zIndex: (theme) => theme.isZoomed ? 1 : 'initial',
        display: 'flex',
        height: 50,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        background: '#ccc',
        padding: 10,
        boxSizing: 'border-box',
        borderRadius: 4,
        fontSize: 20,
        '&:not(:first-of-type)': {
            marginLeft: 10
        },
        '& > svg': {
            width: 20,
            height: 20,
        },
        '&[disabled]': {
            '& > svg': {
                fill: '#aaa'
            },
        }
    },
    amount: {
        fontSize: 18
    }
});

export const Slider = ({
   slidesAmount,
   slug,
   active = 0,
   imgExt = 'jpg',
   imagesSrc = `${process.env.GATSBY_BACKEND_URL}/images-tales`
}: SliderProps) => {
    const [activeSlide, setActiveSlide] = useState(active);
    const [isZoomed, setIsZoomed] = useState(false);
    const firstSlideIndex = 0;
    const lastSlideIndex = slidesAmount - 1;
    const classes = useStyles({isZoomed});

    const nextSlideHandler = () => {
        if (lastSlideIndex > activeSlide) {
            setActiveSlide(prev => prev + 1)
        } else {
            setActiveSlide(lastSlideIndex)
        }
    }
    const prevSlideHandler = () => {
        if (activeSlide > firstSlideIndex) {
            setActiveSlide(prev => prev - 1)
        } else {
            setActiveSlide(firstSlideIndex)
        }
    }

    const handlers = useSwipeable({
        onSwipedLeft: nextSlideHandler,
        onSwipedRight: prevSlideHandler,
    });

    const onInputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);

        if (value > slidesAmount || value < 1) {
            return false;
        }
        setActiveSlide(value - 1 || 0)
    }

    const toggleZoomSlider = (event: React.MouseEvent<HTMLButtonElement>) => {
        setIsZoomed((isZoomed) => !isZoomed);
    }

    return (
        <div {...handlers} className={classes.wrap}>
            <img src={`${imagesSrc}/${slug}/${activeSlide}.${imgExt}`} alt={`${slug}-${activeSlide}`} className={classes.img} />
            <div className={classes.actionPanel}>
                <button className={classes.button} disabled={activeSlide === firstSlideIndex} onClick={() => setActiveSlide(firstSlideIndex)}>
                    <FirstArrowIcon />
                </button>
                <button className={classes.button} disabled={activeSlide === firstSlideIndex} onClick={prevSlideHandler}>
                    <PreviousArrowIcon />
                </button>
                <input className={classes.input} type='number' value={activeSlide + 1} step={1} max={slidesAmount} min={1} onChange={onInputChangeHandler} />
                <span className={classes.amount}> из {slidesAmount} </span>
                <button className={classes.button} disabled={activeSlide === lastSlideIndex} onClick={nextSlideHandler}>
                    <NextArrowIcon />
                </button>
                <button className={classes.button} disabled={activeSlide === lastSlideIndex} onClick={() => setActiveSlide(lastSlideIndex)}>
                    <LastArrowIcon />
                </button>
                <button className={classes.button} onClick={toggleZoomSlider}>
                    <MagnifierArrowIcon />
                </button>
            </div>
        </div>
    );
}
