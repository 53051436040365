import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../components/Layout';
import { AuthorData, ImagesTaleData } from '../types';
import { Slider } from '../components/Slider';


interface ImagesPageTemplateData {
    imagesTales: ImagesTaleData;
    authors?: AuthorData;
}

const useStyles = makeStyles((theme) => ({
    titleWrap: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    title: {
        margin: '0 10px 0 0',
    },
    timeToRead: {
        fontSize: 14,
    },
    author: {
        width: '100%',
        fontSize: 20,
        textAlign: 'center',
    }
}));

export const TextTalePageTemplate: FC<PageProps<ImagesPageTemplateData>> = ({data, location}) => {
    const { imagesTales: {images_amount, slug, name, timeToRead}, authors } = data;
    const classes = useStyles();

    return (
        <Layout location={location} seoTitle={name}>
            {authors && <strong className={classes.author}>{authors.name}</strong>}
            <div className={classes.titleWrap}>
                <h1 className={classes.title}>{name}</h1>
                {timeToRead && <span className={classes.timeToRead}>{timeToRead}</span>}
            </div>
            {' '}
            <Slider slidesAmount={images_amount} slug={slug} />

        </Layout>
    );
}


export const query = graphql`
  query ImagesTalePageTemplateQuery($slug: String!, $author_id: Int) {
      imagesTales(slug: {eq: $slug}) {
        _id
        slug
        name
        images_amount
        categories {
            id
        }
        timeToRead
        author_id
        visits
      }
        authors(_id: {eq: $author_id}) {
            name
            slug
            _id
        }
  }
`
export default TextTalePageTemplate;
